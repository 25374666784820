import React, { useEffect } from "react";

import { currentUserVar, useReactiveVar } from "@apollo";

// Component imports
import {
  AppleLoginButton,
  DividerLineWithText,
  ExternalLink,
  FacebookLoginButton,
  Link,
  SignUpForm,
} from "@components";
import { Grid, Paper, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "auto",
    minHeight: 663,
    width: 372,
    border: "1px solid #DADADA",
    borderRadius: 8,
    margin: "auto",
    marginTop: 19,
    marginBottom: 56,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginTop: 24,
      marginBottom: 24,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    width: 308,
    margin: "3px auto 25px auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0px auto 16px auto",
    },
  },
  header: {
    marginTop: 16,
    marginBottom: 6,
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 8,
      fontSize: 18,
    },
  },
  termsContainer: {
    width: 312,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  termsText: {
    textAlign: "center",
    color: "#464646",
    fontSize: 12,
    marginTop: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  logInText: {
    textAlign: "center",
    color: "#464646",
    fontSize: 18,
    marginTop: 10,
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
      fontSize: 16,
    },
  },
}));

export default function SignUp() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signUp", "common"]);

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) navigate("/", { replace: true });
  }, []);

  return user ? null : (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={10}
          className={classes.container}
        >
          <Grid className={classes.textFieldWidth}>
            <Grid>
              <Typography className={classes.header}>{t("signUp")}</Typography>
            </Grid>
            <Grid item>
              <SignUpForm />
              <DividerLineWithText text={t("common:or")} />
              <FacebookLoginButton />
              <AppleLoginButton />
            </Grid>
            <Grid className={classes.termsContainer}>
              <Typography className={classes.termsText} style={{ marginTop: 8 }}>
                {t("signUpDisclaimer.one")}
                <ExternalLink to="https://www.coinmiles.io/usersterms" newTab>
                  <strong>{t("signUpDisclaimer.two")}</strong>
                </ExternalLink>
                {t("signUpDisclaimer.three")}
                <ExternalLink to="https://www.coinmiles.io/privacy" newTab>
                  <strong>{t("signUpDisclaimer.four")}</strong>
                </ExternalLink>
                {t("signUpDisclaimer.five")}
              </Typography>
              <Typography className={classes.logInText}>
                {t("alreadyMember")}{" "}
                <Link to="/sign-in">
                  <strong>{t("common:signIn")}</strong>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
